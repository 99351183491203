
import Vue from 'vue';

export default Vue.extend({
  props: {
    name: {
      type: String,
      default: null,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: false,
    };
  },
  computed: {
    inputClasses(): string[] {
      return this.createClassesArray('inpt');
    },
  },
  methods: {
    createClassesArray(classesPrefix: string) {
      let classesArray: string[] = [classesPrefix];

      let variant: string = 'switch';
      let type: string = 'checkbox';
      let required: string = this.required.toString();

      variant = variant ? `${classesPrefix}--${variant}` : `${classesPrefix}--default`;
      type = type ? `${classesPrefix}--${type}` : `${classesPrefix}--text`;
      required = this.required ? `${classesPrefix}--required` : '';

      classesArray = [...classesArray, variant, type, required];
      return classesArray;
    },
    autoSave() {
      this.$nextTick(function() {
        this.$emit('auto-save');
      });
    },
  },
  watch: {
    value: function() {
      this.$emit('input', this.value);
    },
  },
});
